<template>
  <div class="page mb-10">
    <div class="flex page-content-body container mx-auto"
         style="display: flex; flex-direction: column; align-items: center; justify-content: center;padding-top: 40px">
      <!--      <div class="bg-base-300 payment-info-wrap p-5 rounded-md shadow-2xl">-->
      <!--        <div v-if="isLoading">-->
      <!--          <ProgressSpinner/>-->
      <!--        </div>-->
      <!--        <p v-else class="text-sm" v-html="contentData.body"></p>-->
      <!--      </div>-->

      <h1 class="text-center page-title" style="padding-bottom: 40px;color: hsla(0, 0%, 100%, .8); font-size: 30px">
        Дансны мэдээлэл</h1>
      <div class="ml-4">
        <div class="bg-base-100 rounded-md shadow-2xl">
          <div class="account-info shadow-2xl" style="background: #121212">
            <div class="w-loader" v-if="isLoadingInfo">
              <ProgressSpinner/>
            </div>
            <table v-else class="b-info-table">
              <tr>
                <td>Шилжүүлэх банк</td>
                <td style="color: hsla(0, 0%, 100%, .8)">{{ paymentData.bank_name }}</td>
                <td></td>
              </tr>
              <tr>
                <td>Дансны дугаар</td>
                <td><span id="bankAccount" style="color: hsla(0, 0%, 100%, .8)">{{ paymentData.account_number }}</span>
                </td>
                <td>
                  <Button style="color: #E67E22" icon="pi pi-copy" v-tooltip.top="'Хуулах'"
                          class="p-button-rounded p-button-danger p-button-outlined" @click="copyTxt('bankAccount')"/>
                </td>
              </tr>
              <tr>
                <td>Данс эзэмшигч</td>
                <td style="color: hsla(0, 0%, 100%, .8)">{{ paymentData.account_holder_name }}</td>
                <td></td>
              </tr>
              <tr>
                <td>Гүйлгээний утга</td>
                <td><strong id="userId" style="color: hsla(0, 0%, 100%, .8); font-weight: bold; font-size: 20px">{{
                    user.id
                  }}</strong></td>
                <td>
                  <Button style="color: #E67E22" icon="pi pi-copy" v-tooltip.top="'Хуулах'"
                          class="p-button-rounded p-button-danger p-button-outlined" @click="copyTxt('userId')"/>
                </td>
              </tr>
              <tr>
                <td>Төлөх дүн</td>
                <td><strong id="amount" style="color: hsla(0, 0%, 100%, .8); font-weight: bold; font-size: 20px">{{
                    formattedPrice(this.$route.query.amount)
                  }}₮</strong></td>
                <td>
                  <Button style="color: #E67E22" icon="pi pi-copy" v-tooltip.top="'Хуулах'"
                          class="p-button-rounded p-button-danger p-button-outlined" @click="copyTxt('amount')"/>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Transfer",
  data() {
    return {
      isLoading: true,
      isLoadingInfo: true
    }
  },
  computed: {
    ...mapGetters(['user', 'contentData', 'paymentData']),
  },
  created() {
    this.$store.dispatch("getContentData", {vm: this, id: 1})
    this.$store.dispatch("getPaymentData", {vm: this})
  },
  methods: {
    formattedPrice(price) {
      if (typeof price === "number") {
        return new Intl.NumberFormat('mn-MN', {
          minimumFractionDigits: 0
        }).format(price);
      }
      return price;
    },
    copyTxt(id) {
      let clipTxt = document.querySelector(`#${id}`)
      navigator.clipboard.writeText(clipTxt.textContent);
      this.$toast.success('Амжилттай хуулагдлаа.');
    },
  }
}
</script>

<style scoped>
.content-page {
  height: 100vh;
  padding-top: 100px;
}
</style>